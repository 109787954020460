import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('unsaved-settings-dialog',{attrs:{"value":_vm.unsavedSettings}}),_c('scrollable',{staticClass:"col-4 pa-0",attrs:{"height":"calc(100vh - 64px)"},scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mx-0"},[_c('span',[_vm._v(_vm._s(_vm.$t('Settings')))])])],1)]},proxy:true},{key:"scrollable",fn:function(){return [_c('tab-navigation-route-menu',{attrs:{"items":_vm.tabs},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.unsavedSettings)),function(unsavedKey){return {key:("tab." + unsavedKey),fn:function(){return [_c('span',{key:unsavedKey},[_vm._v(" "+_vm._s(unsavedKey)+" "),_c(VIcon,[_vm._v("warning")])],1)]},proxy:true}})],null,true),model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}})]},proxy:true}])}),_c(VDivider,{staticClass:"foreground-divider",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex flex-grow-1"},[(_vm.settings)?_c(VTabsItems,{staticClass:"col-8 pa-0",model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},[_vm._l((_vm.tabs),function(tabItem,index){return [_c(VTabItem,{key:tabItem.code},[_c(tabItem.component,{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTabIndex === index && tabItem.component && tabItem.submit),expression:"selectedTabIndex === index && tabItem.component && tabItem.submit"}],tag:"component",on:{"submit":_vm.updateSetting,"change":_vm.onChange},scopedSlots:_vm._u([(tabItem.header)?{key:"header",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"d-flex justify-space-between pa-4 align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t(tabItem.header)))]),_c(VBtn,{attrs:{"depressed":"","disabled":!valid || !tabItem.permissions,"loading":_vm.loading,"type":'submit',"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)]}}:null],null,true),model:{value:(_vm.currentSettings),callback:function ($$v) {_vm.currentSettings=$$v},expression:"currentSettings"}})],1)]})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }