import { render, staticRenderFns } from "./SettingsNotificationMailingDisabledTemplates.vue?vue&type=template&id=d6d7b768&"
import script from "./SettingsNotificationMailingDisabledTemplates.vue?vue&type=script&lang=ts&"
export * from "./SettingsNotificationMailingDisabledTemplates.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports