import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"scrollable",fn:function(){return [_c('div',{staticClass:"pa-6 mx-8"},[_c('span',{staticClass:"text-h5 font-weight-medium"},[_vm._v(_vm._s(_vm.$t('Plugins')))]),_c(VDataTable,{staticClass:"mt-4",attrs:{"items":_vm.plugins,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(_vm.settingExists(item.key).value ? 'router-link' : 'span',{tag:"router-link",staticClass:"text-capitalize",attrs:{"to":{ name: 'settings-plugins', params: { option: item.key } }}},[_vm._v(" "+_vm._s(item.key.replaceAll('_', ' '))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.settingExists(item.key).value)?_c(VBtn,{attrs:{"depressed":"","icon":"","to":{ name: 'settings-plugins', params: { option: item.key } }}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("edit")])],1):_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }