import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('subresource-expansion-panel',{attrs:{"can-create":_vm.canCreate},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Add printer to the list')))])]},proxy:true},{key:"content",fn:function(){return [_c('form-validator-wrapper',{staticClass:"mt-4",on:{"submit":_vm.createItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('star-printer-printer-inputs',{key:_vm.value.length,model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])],1)]}}])})]},proxy:true}])}),_c(VDataTable,{staticClass:"v-data-table-shadowless",attrs:{"show-expand":"","single-expand":"","items":_vm.indexedPrinters,"item-key":"index","headers":_vm.headers,"hide-default-footer":_vm.indexedPrinters.length < 5},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-6",attrs:{"colspan":headers.length}},[_c(VCard,{attrs:{"flat":""}},[_c('form-validator-wrapper',{on:{"submit":function($event){return _vm.updateItem(item.index)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('star-printer-printer-inputs',{attrs:{"value":item.printer},on:{"input":function($event){_vm.expandedItem = $event}}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"secondary"},on:{"click":function($event){return _vm.deleteItem(item.index)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete printer'))+" ")]),_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Update printer'))+" ")])],1)]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }