import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{attrs:{"height":("calc(100vh - " + (_vm.$vuetify.application.top) + "px)")},scopedSlots:_vm._u([{key:"scrollable",fn:function(){return [(_vm.canManage)?_c('div',{staticClass:"mx-4"},[_c('subresource-expansion-panel',{attrs:{"can-create":_vm.canCreate},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Languages')))])]},proxy:true},{key:"content",fn:function(){return [(_vm.canCreate)?_c('form-validator-wrapper',{on:{"submit":_vm.createItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('language-inputs',{attrs:{"languages":_vm.translationLanguages},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"loading":_vm.loadingCreate,"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)]}}],null,false,3302122758)}):_vm._e()]},proxy:true}],null,false,2288145642)}),_c(VDataTable,{staticClass:"v-data-table-shadowless",attrs:{"show-expand":"","single-expand":"","items":_vm.translationLanguages,"headers":_vm.headers,"item-key":"code"},on:{"item-expanded":_vm.onExpand},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-6",attrs:{"colspan":headers.length}},[_c(VCard,{attrs:{"flat":""}},[_c('form-validator-wrapper',{on:{"submit":_vm.updateItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('language-inputs',{key:item.code,attrs:{"edit-mode":""},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}}),_c('div',{staticClass:"d-flex justify-space-between my-4"},[(_vm.canDelete)?_c(VBtn,{attrs:{"loading":_vm.loadingDelete,"depressed":""},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")]):_vm._e(),(_vm.canUpdate)?_c(VBtn,{attrs:{"loading":_vm.loadingUpdate,"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)]}}],null,false,1857501562)})],1):_c('restricted',{attrs:{"message":"You don't have permissions to manage languages"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }