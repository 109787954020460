import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("You're leaving settings")))]),_c(VDivider),_c(VCardText,{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('You have some settings changed and not saved. Are you sure you want to leave?'))+" ")]),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.denyLeave}},[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")]),_c(VBtn,{attrs:{"depressed":"","text":""},on:{"click":_vm.confirmLeave}},[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }