import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCombobox,{attrs:{"outlined":"","label":_vm.$t('Allowed List'),"items":_vm.countries,"item-value":"code","item-text":"name","return-object":false,"multiple":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c(VSlideYReverseTransition,[(_vm.countries)?_c(VChip,{attrs:{"value":selected,"label":"","close":""},on:{"click:close":function($event){_vm.allowedList = _vm.allowedList.filter(function (code) { return code !== item; })}}},[_vm._v(" "+_vm._s(_vm.getCountryName(item))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.allowedList),callback:function ($$v) {_vm.allowedList=$$v},expression:"allowedList"}})}
var staticRenderFns = []

export { render, staticRenderFns }