import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"grid-props":{ perPage: -1 }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'organisation-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new organisation'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'organisation-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.console.domains",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2"},[_c('organisation-domain-links',{staticClass:"d-flex",attrs:{"value":item.domains,"app":"console"}})],1)]}},{key:"item.pos.domains",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2"},[_c('organisation-domain-links',{staticClass:"d-flex",attrs:{"value":item.domains,"app":"pos"}})],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'organisation-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('organisation-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.fetchOrganisations,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Organisations') },false))}
var staticRenderFns = []

export { render, staticRenderFns }