import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCombobox,_vm._b({attrs:{"items":_vm.items,"outlined":"","item-text":function (item) { return _vm.capitalizeEachWord(_vm.replaceDotsToSpace(item.code)); },"multiple":"","item-value":"code","return-object":false,"hide-details":"auto"},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(_vm.items)?_c(VChip,{staticClass:"text-capitalize",attrs:{"value":selected,"label":"","close":""},on:{"click:close":function($event){return _vm.removeItem(item)}}},[_vm._v(" "+_vm._s(_vm.replaceDotsToSpace(item))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"model"}},'v-combobox',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }