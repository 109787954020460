import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCombobox,{attrs:{"items":_vm.countries || [],"hide-details":"auto","multiple":"","outlined":"","label":_vm.$t('Allowed Telephones List'),"item-value":"code","item-text":function (item) { return _vm.getCountryWithPhone(item.code); },"return-object":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selected = ref.selected;
var item = ref.item;
return [_c(VSlideYReverseTransition,[(_vm.countries)?_c(VChip,{attrs:{"value":selected,"label":"","close":""},on:{"click:close":function($event){_vm.allowedTelephoneList = _vm.allowedTelephoneList.filter(function (code) { return code !== item; })}}},[_vm._v(" "+_vm._s(_vm.getCountryWithPhone(item))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.allowedTelephoneList),callback:function ($$v) {_vm.allowedTelephoneList=$$v},expression:"allowedTelephoneList"}})}
var staticRenderFns = []

export { render, staticRenderFns }